import { useForm } from "react-hook-form";
import React from "react";
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Image,
  Heading
} from "@chakra-ui/core";

import useAuth from '../utils/use-auth';

import Logotype from '../images/logo.webp';

interface FormProps {
    onFormSuccess: () => void;
}

type LoginFormData = {
    email: string;
    password: string;
}

const yupSchema = yup.object().shape<LoginFormData>({
    email: yup.string().required("E-post måste vara angivet.").email("Angiven e-post är ej giltig."),
    password: yup.string().required("Lösenord måste vara angivet.")
});

const LoginForm: React.FC<FormProps> = ({ onFormSuccess }) => {

    const { login } = useAuth();

    const [hasFormError, setHasFormError] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const handlePasswordClick = () => setShow(!show);

    const { handleSubmit, errors, register, formState } = useForm<LoginFormData>({
        resolver: yupResolver(yupSchema)
    });

    const handleOnSubmit = handleSubmit(async ({email, password}) => {
        setHasFormError(false);
        const success = await login(email, password);

        if (success) {
            onFormSuccess();
        } else {
            setHasFormError(true);
        }
    });

    return (
    <Flex direction="column" align="center">
        <Box
            mb={16}
            w="sm"
        >
            <Image src={Logotype}  />
            <Heading as="h2" size="md" textAlign="right">Licenshantering</Heading>
        </Box>
        <Box as="form" width="full" maxWidth="sm" onSubmit={handleOnSubmit}>
            <FormControl isInvalid={errors.email !== undefined} mb={4}>
                <FormLabel htmlFor="email">E-post</FormLabel>
                <Input
                    type="email" 
                    id="email"
                    name="email"
                    variant="filled"
                    placeholder="Exempel@domän.se"
                    ref={register}
                />
                <FormErrorMessage>
                    {errors.email && errors.email.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password !== undefined}>
                <FormLabel htmlFor="password">Lösenord</FormLabel>
                <InputGroup>
                    <Input
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        id="password"
                        name="password"
                        variant="filled"
                        placeholder="Lösenord"
                        ref={register}
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handlePasswordClick}>
                        {show ? "Dölj" : "Visa"}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                    {errors.password && errors.password.message}
                </FormErrorMessage>
            </FormControl>
            <Flex
                align="center"
                justify="flex-end"
            >
                <Button
                    my={8}
                    variantColor="brand"
                    isLoading={formState.isSubmitting}
                    type="submit"
                >
                    Logga in
                </Button>
            </Flex>
            {hasFormError && (
                <Alert status="error" borderRadius="2px">
                    <AlertIcon />
                    <Flex direction="column">
                    <AlertTitle>Användare eller lösenord är felaktigt!</AlertTitle>
                    <AlertDescription>Vänligen försök igen.</AlertDescription>
                    </Flex>
              </Alert>
            )}
        </Box>
    </Flex>
    );
}

export default LoginForm;