import React, { useEffect, useState } from 'react';
import { Flex, Button, Spinner } from '@chakra-ui/core';
import Select, { OptionsType, ValueType } from 'react-select';
import ModalBase from './ModalBase';
import useUser from '../utils/use-user';
import useLicense, { LicenseAdministrator } from '../utils/use-license';

type SelectOption = {
    label: string;
    value: number;
};

type ManageAdministratorsModalType = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (activeAdministrators: LicenseAdministrator[]) => void;
    existingAdministrators: LicenseAdministrator[] | null;
    activeLicenseId: number;
};

const ManageAdministratorsModal: React.FunctionComponent<ManageAdministratorsModalType> = ({ isOpen, onClose, onSuccess, existingAdministrators, activeLicenseId }) => {

    const { getAllUsers } = useUser();
    const { setLicenseAdminstrators } = useLicense();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [users, setUsers] = useState<OptionsType<SelectOption>>();
    const [selectedUsers, setSelectedUsers] = useState<ValueType<SelectOption> | null>(null);

    const handleOnSelectOption = (e?: ValueType<SelectOption>): void => {
        if (e) {
            setSelectedUsers(e);
            console.log(e);
        }
    };

    const handleSaveAdministrators = async () => {
        setIsSubmitting(true);
        
        const selectedIds = (selectedUsers as OptionsType<SelectOption>).map(x => x.value) || [];
        const result = await setLicenseAdminstrators(activeLicenseId, selectedIds);
        setIsSubmitting(false);

        if (result !== null) {
            onSuccess(result);
            onClose();
        }
    }

    const loadAdministrators = async () => {
        setIsLoadingUsers(true);
        const users = await getAllUsers();
        const administrators = users?.filter(x => x.role.toLowerCase() !== 'superadmin').map<SelectOption>(user => ({ value: user.userId, label: user.userName }));

        setIsLoadingUsers(false);
        setUsers(administrators);

        setSelectedUsers(existingAdministrators?.map<SelectOption>(admin => ({ value: admin.id, label: admin.userName })));
    }

    useEffect(() => {
        loadAdministrators();
    }, [isOpen]);

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} modalTitle="Hantera administratörer">
            
            {isLoadingUsers ? <Spinner /> : <Select isMulti onChange={handleOnSelectOption} value={selectedUsers} options={users} placeholder="Välj administratörer" />}
            
            <Flex
                align="center"
                justify="flex-end"
                pt={8}
                pb={2}
            >   
                <Button
                    variantColor="brand"
                    type="submit"
                    order={2}
                    isLoading={isSubmitting}
                    onClick={handleSaveAdministrators}
                >
                    Spara
                </Button>
                <Button mr={4} order={0} variant="ghost" onClick={onClose}>
                    Avbryt
                </Button>
            </Flex>
        </ModalBase>
    );
}

export default ManageAdministratorsModal;