import React, { useState, useEffect } from 'react';
import { Box, Spinner, Flex, Heading, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useToast, Tag } from '@chakra-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import useUser, { UserType } from '../utils/use-user';
import { MenuItemLink } from '../components/Header';
import { FiArrowLeft, FiTrash2 } from 'react-icons/fi';
import { useAppContext } from '../utils/AppContext';

const UserView = (props: UserType) => {

    const {userName, userId, role, email} = props;

    const { deleteSingleUser } = useUser();

    const context = useAppContext();
    const history = useHistory();
    const toast = useToast();

    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const handleOnDeleteClose = () => setIsDeleteOpen(false);
    const cancelRef = React.createRef<HTMLElement>();

    const handleDeleteLicenseClick = async () => {
        const deleteStatus = await deleteSingleUser(userId);

        if (deleteStatus) {
            toast({
                position: "bottom-right",
                title: "Användaren borttagen.",
                description: "Användaren blev borttagen med lyckat resultat.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            history.push('/anvandare');
        }
    }

    return (
        <>
            <Flex mx={-5} mt={-5} p={5} borderBottom="1px solid" borderBottomColor="gray.200" mb={8} align="center" justify="space-between">
            <MenuItemLink to="/anvandare" leftIcon={FiArrowLeft}>
                Tillbaka
            </MenuItemLink>

            {/* Temporal fix to not remove ALL accounts, ie. keep the hulk */}
            {context.user.get?.isAdmin && email !== 'advaniakristianstad@gmail.com' && (
                <Button variantColor="red" variant="outline" leftIcon={FiTrash2} onClick={() => setIsDeleteOpen(true)}>
                    Ta bort
                </Button>
            )}
        </Flex>
        <Flex align="center" justify="space-between" mb={8}>
            <Box>
                <Tag variantColor={role && role.toLowerCase() === 'superadmin' ? 'brand' : 'blue'} mb={4}>{role}</Tag>
                <Heading as="h1">
                    {userName}
                </Heading>
            </Box>
        </Flex>
        <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleOnDeleteClose}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Ta bort användare
            </AlertDialogHeader>

            <AlertDialogBody>
                Är du säker på att du vill ta bort användaren <strong>{userName}</strong>?
            </AlertDialogBody>

            <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleOnDeleteClose}>
                Nej, avbryt
                </Button>
                <Button variantColor="red" onClick={handleDeleteLicenseClick} ml={4}>
                Ja, ta bort
                </Button>
            </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
        </>
    );
}

const SingleUser = () => {

    const { id } = useParams();
    const { getSingleUser } = useUser();

    const [loadedUser, setLoadedUser] = useState<UserType | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const loadUser = async (id: string) => {
        
        const user = await getSingleUser(id);

        setLoadedUser(user);
        setIsLoading(false);
    }

    useEffect(() => {
        loadUser(id);
    }, [id]);

    return (
        <Box
            h="full"
            minH="fill-available"
            bg="white"
            rounded="md"
            p={5}
            mb={8}
        >
            {isLoading 
            ? <Spinner /> 
            : loadedUser === null 
                ? <Box>Antingen saknar du behörighet, eller så finns inte den efterfrågade användaren.</Box>
                : <UserView {...loadedUser} />
            }
        </Box>
    );
}

export default SingleUser;