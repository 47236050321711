import React from 'react';
import { Route } from 'react-router';

import ProtectedRoute from './ProtectedRoute';
import {AuthRoutes} from '../utils/use-auth';
import Layout from './Layout';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Licensing from '../pages/Licensing';
import SingleLicense from '../pages/SingleLicense';
import SingleUser from '../pages/SingleUser';
import Users from '../pages/Users';

const App = () => {
  
    return (
      <Layout>
        <ProtectedRoute exact path='/' component={Home} requireAdmin={false} />
        <ProtectedRoute exact path="/licenser" component={Licensing} requireAdmin={false} />
        <ProtectedRoute path="/licenser/:id" component={SingleLicense} requireAdmin={false} />
        <ProtectedRoute exact path="/anvandare" component={Users} requireAdmin />
        <ProtectedRoute path="/anvandare/:id" component={SingleUser} requireAdmin />
        <Route path={AuthRoutes.Login} component={Login} />
      </Layout>
    );
}

export default App;