import React from 'react';
import { Box, FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, Flex, Button, Alert, AlertIcon, AlertTitle, AlertDescription, useToast } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import useLicense, { SeatLicenseModel } from '../utils/use-license';
import ModalBase from './ModalBase';

type FormData = {
    email: string;
}

const FormSchema = yup.object<FormData>({
    email: yup.string().email("Angiven e-post är ej giltig.").required("E-post måste vara angivet."),
});

interface FormProps {
    onFormSuccess: (addedSeat:SeatLicenseModel) => void;
    activeLicenseId: number;
}

const AddSeatForm:React.FC<FormProps> = ({ onFormSuccess, children, activeLicenseId }) => {

    const { addSeatToLicense } = useLicense();
    const toast = useToast();

    const [hasFormError, setHasFormError] = React.useState(false);
    const { handleSubmit, errors, register, formState } = useForm<FormData>({ resolver: yupResolver(FormSchema) });

    const nameRef = React.useRef<HTMLInputElement | null>(null);

    const handleOnSubmit = handleSubmit(async ({email}) => {
        
        setHasFormError(false);

        const success = await addSeatToLicense(activeLicenseId, email);

        if (success !== null) {
            toast({
                position: "bottom-right",
                title: "Sätet tilldelat.",
                description: "Sätet blev tilldelat med lyckat resultat.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            onFormSuccess(success);
        } else {
            setHasFormError(true);
        }
    });

    React.useEffect(() => {
        nameRef.current && nameRef.current.focus();
    }, []);

    return (
        <Box as="form" onSubmit={handleOnSubmit}>
            <FormControl isInvalid={errors.email !== undefined} mb={4}>
                <FormLabel htmlFor="email">E-post</FormLabel>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    variant="filled"
                    placeholder="namn.fornamn@domän.se"
                    ref={(e: HTMLInputElement) => {
                        register(e);
                        nameRef.current = e;
                    }}
                />
                <FormErrorMessage>
                    {errors.email && errors.email.message}
                </FormErrorMessage>
            </FormControl>
            
            <Flex
                align="center"
                justify="flex-end"
                pt={8}
                pb={2}
            >   
                <Button
                    variantColor="brand"
                    isLoading={formState.isSubmitting}
                    type="submit"
                    order={2}
                >
                    Lägg till
                </Button>
                {children}
            </Flex>
            {hasFormError && (
                <Alert status="error" borderRadius="2px">
                    <AlertIcon />
                    <Flex direction="column">
                    <AlertTitle>Något gick fel</AlertTitle>
                    <AlertDescription>Något gick fel när sätet skulle tilldelas. Vänligen försök igen!</AlertDescription>
                    </Flex>
              </Alert>
            )}
        </Box>
    );
}

type AddSingleSeatModalType = {
    isOpen: boolean;
    onClose: () => void;
    activeLicenseId: number;
    onSuccess: (addedSeat: SeatLicenseModel) => void;
};

const AddSingleSeatModal: React.FunctionComponent<AddSingleSeatModalType> = ({ isOpen, onClose, activeLicenseId, onSuccess }) => {

    const handleOnSuccess = (addedSeat: SeatLicenseModel) => {
        onClose();
        onSuccess(addedSeat);
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} modalTitle="Tilldela säte">
            <AddSeatForm onFormSuccess={handleOnSuccess} activeLicenseId={activeLicenseId}>
                <Button mr={4} order={0} variant="ghost" onClick={onClose}>
                    Avbryt
                </Button>
            </AddSeatForm>
        </ModalBase>
    );
}

export default AddSingleSeatModal;