import React, { createContext, useState, useContext } from 'react';

type InitialProps = {
    initialBaseUrl: string | undefined
}

export type ApplicationUser = {
    authorized: boolean;
    username: string;
    isAdmin: boolean;
}


type AppProps = {
    baseUrl: { get: string, set: (newState: string) => void };
    user: { get: ApplicationUser | null, set: (newState: ApplicationUser | null) => void}
}

export const AppContext = createContext<AppProps | null>(null);

export const useAppContext = () => {
    const context = useContext(AppContext);

    if (context === null) {
        throw Error("Missing AppContext");
    } else {
        return context;
    }
}

const AppContextProvider: React.FunctionComponent<InitialProps> = ({children, initialBaseUrl}) => {

    const [baseUrl, setBaseUrl] = useState<string>(initialBaseUrl ?? '');
    const [user, setUser] = useState<ApplicationUser | null>(null);

    const initialState: AppProps = {
        baseUrl: { get: baseUrl, set: setBaseUrl },
        user: { get: user, set: setUser }
    }

    return (
        <AppContext.Provider value={initialState}>
            {children}
        </AppContext.Provider>
    );
}

export default AppContextProvider;