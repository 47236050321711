import { useAppContext } from './AppContext';
import useAuth from './use-auth';

const useSeat = () => {

    const context = useAppContext();
    const seatsUrl = `${context.baseUrl.get}/api/seats`;

    const { authPostRequest, authGetRequest, authPutRequest, authDeleteRequest } = useAuth();

    const deleteSeat = async (id: number) => {
        return await authDeleteRequest<boolean>(`${seatsUrl}/${id}`);
    }

    return {
        deleteSeat
    }
}

export default useSeat;