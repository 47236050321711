import { useAppContext } from './AppContext';
import useAuth from './use-auth';


type SeatLicenseType = {
    name: string;
}

export type LicenseListModel = {
    name: string;
    licenseType: string;
    licenseId: number;
}

export type DomainLicenseModel = {
    domainLicenseId: number;
    domain: string;
}

export type SeatLicenseModel = {
    seatLicenseId: number;
    email: string;
}

export type SingleLicenseFullModel = {
    name: string;
    licenseType: string;
    licenseId: number;
    numberOfSeats: number;
    domainLicenses: DomainLicenseModel[] | null;
    seatLicenses: SeatLicenseModel[] | null;
    administrators: LicenseAdministrator[] | null;
}

export type BaseLicenseUpdateModel = {
    name: string;
    licenseId: number;
    numberOfSeats: number;
}

export type LicenseAdministrator = {
    userName: string;
    id: number;
}

const licenseType = {
    domain: 'domain',
    seat: 'seat'
}

const useLicense = () => {

    const context = useAppContext();
    const licenseUrl = `${context.baseUrl.get}/api/licenses`;

    const { authPostRequest, authGetRequest, authPutRequest, authDeleteRequest, authUploadRequest } = useAuth();

    const createDomainLicense = async (name: string, domain: string) => {

        if (!context.user.get?.isAdmin) {
            return null;
        }

        return await authPostRequest<{ name: string, licenseType: string, numberOfSeats: number}>(`${licenseUrl}`, {name, domains: [domain], licenseType: licenseType.domain});
    }

    const createSeatLicense = async (name: string, numberOfSeats: number) => {

        if (!context.user.get?.isAdmin) {
            return null;
        }

        return await authPostRequest<{ name: string, licenseType: string, numberOfSeats: number}>(`${licenseUrl}`, {name, numberOfSeats, licenseType: licenseType.seat});
    }

    const updateLicenseBase = async (licenseId: number, name:string, numberOfSeats: number) => {
        return await authPutRequest<SingleLicenseFullModel>(licenseUrl, {licenseId, name, numberOfSeats});
    }

    const getLicenses = async () => {
        return await authGetRequest<LicenseListModel[]>(licenseUrl);
    }

    const getSingleLicense = async (id:number) => {
        return await authGetRequest<SingleLicenseFullModel>(`${licenseUrl}/${id}`);
    }

    const deleteSingleLicense = async (id:number) => {
        return await authDeleteRequest<boolean>(`${licenseUrl}/${id}`);
    }

    const addSeatToLicense = async (id: number, email: string) => {
        return await authPostRequest<SeatLicenseModel>(`${licenseUrl}/${id}/seat`, {email});
    }

    const addSeatsFileToLicense = async (id: number, formData: FormData) => {
        return await authUploadRequest<SeatLicenseModel[]>(`${licenseUrl}/${id}/seats`, formData);
    }

    const setLicenseAdminstrators = async (id: number, userIds: number[]) => {
        return await authPostRequest<LicenseAdministrator[]>(`${licenseUrl}/${id}/administrators`, {userIds});
    }

    return {
        createDomainLicense,
        createSeatLicense,
        getLicenses,
        getSingleLicense,
        updateLicenseBase,
        addSeatToLicense,
        addSeatsFileToLicense,
        deleteSingleLicense,
        setLicenseAdminstrators
    }
}

export default useLicense;