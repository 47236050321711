import React from 'react';
import { Box, Heading } from '@chakra-ui/core';


const Home = () => (
    <Box
        h="full"
        minH="-webkit-fill-available"
        bg="white"
        rounded="md"
        p={5}
        mb={8}
    >
        <Heading as="h1">
            Start
        </Heading>
    </Box>
);

export default Home;