import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, FormErrorMessage, Flex, Button, Alert, AlertIcon, AlertTitle, AlertDescription, useToast, Checkbox, FormHelperText } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import useUser, { CreateUserRequestType } from '../utils/use-user';
import ModalBase from './ModalBase';

const FormSchema = yup.object<CreateUserRequestType>({
    email: yup.string().email("Angiven e-post är ej giltig.").required("E-post måste vara angivet."),
    userName: yup.string().min(3, "Användarnamnet ska bestå av minst 3 tecken.").required("Användarnamn måste vara angivet."),
    password: yup.string().min(8, "Lösenordet ska bestå av minst 8 tecken.").required("Lösenord måste vara angivet."),
    isAdmin: yup.bool().required()
});

interface FormProps {
    onFormSuccess: () => void;
}

const CreateUserForm:React.FC<FormProps> = ({ onFormSuccess, children }) => {

    const { createUser } = useUser();
    const toast = useToast();

    const [hasFormError, setHasFormError] = React.useState(false);
    const { handleSubmit, errors, register, formState } = useForm<CreateUserRequestType>({ resolver: yupResolver(FormSchema) });

    const nameRef = React.useRef<HTMLInputElement | null>(null);

    const handleOnSubmit = handleSubmit(async ({email, userName, password, isAdmin}: CreateUserRequestType) => {
        
        setHasFormError(false);

        const success = await createUser({email, userName, password, isAdmin});

        if (success !== null) {
            toast({
                position: "bottom-right",
                title: "Användaren skapad.",
                description: "Användaren blev skapad med lyckat resultat.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            onFormSuccess();
        } else {
            setHasFormError(true);
        }
    });

    React.useEffect(() => {
        nameRef.current && nameRef.current.focus();
    }, []);

    const [userName, setUserName] = useState('');

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value.replace(' ', ''));
    }

    return (
        <Box as="form" onSubmit={handleOnSubmit}>
            <FormControl isInvalid={errors.userName !== undefined} mb={4}>
                <FormLabel htmlFor="username">Användarnamn</FormLabel>
                <Input
                    id="username"
                    name="userName"
                    variant="filled"
                    placeholder="AdminAdvania"
                    value={userName}
                    onChange={handleUserNameChange}
                    ref={(e: HTMLInputElement) => {
                        register(e);
                        nameRef.current = e;
                    }}
                />
                <FormHelperText>
                    Användarnamnet används vid visning. 
                </FormHelperText>
                <FormErrorMessage>
                    {errors.userName && errors.userName.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.email !== undefined} mb={4}>
                <FormLabel htmlFor="email">E-post</FormLabel>
                <Input
                    type="email"
                    placeholder="epost@domän.se"
                    name="email"
                    variant="filled"
                    ref={register}
                />
                <FormHelperText>
                    E-post används vid inloggning och lösenordsåterställning
                </FormHelperText>
                <FormErrorMessage>
                    {errors.email && errors.email.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password !== undefined} mb={4}>
                <FormLabel htmlFor="password">Lösenord</FormLabel>
                <Input
                    id="password"
                    name="password"
                    variant="filled"
                    placeholder="ex. @dvan1a2020"
                    ref={register}
                />
                <FormHelperText>
                    Lösenordet bör bestå av en blandning av olika tecken.
                </FormHelperText>
                <FormErrorMessage>
                    {errors.password && errors.password.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="isadmin">Skapa som superadmin?</FormLabel>
                <Checkbox
                    id="isadmin"
                    name="isAdmin"
                    ref={register}
                />
                <FormHelperText>
                    Om markerad så skapas användaren som Superadmin.
                </FormHelperText>
                <FormErrorMessage>
                    {errors.isAdmin && errors.isAdmin.message}
                </FormErrorMessage>
            </FormControl>
            <Flex
                align="center"
                justify="flex-end"
                pt={8}
                pb={2}
            >   
                <Button
                    variantColor="brand"
                    isLoading={formState.isSubmitting}
                    type="submit"
                    order={2}
                >
                    Skapa
                </Button>
                {children}
            </Flex>
            {hasFormError && (
                <Alert status="error" borderRadius="2px">
                    <AlertIcon />
                    <Flex direction="column">
                    <AlertTitle>Något gick fel</AlertTitle>
                    <AlertDescription>Något gick fel när användaren skulle skapas. Vänligen försök igen!</AlertDescription>
                    </Flex>
              </Alert>
            )}
        </Box>
    );
}

type CreateUserModalType = {
    isOpen: boolean;
    onClose: () => void;
};

const CreateUserModal: React.FunctionComponent<CreateUserModalType> = ({ isOpen, onClose }) => {

    const handleOnSuccess = () => {
        onClose();
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} modalTitle="Skapa användare">
            <CreateUserForm onFormSuccess={handleOnSuccess}>
                <Button mr={4} order={0} variant="ghost" onClick={onClose}>
                    Avbryt
                </Button>
            </CreateUserForm>
        </ModalBase>
    );
}

export default CreateUserModal;