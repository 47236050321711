import React from 'react';
import { Box, FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, Flex, Button, Alert, AlertIcon, AlertTitle, AlertDescription, useToast } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import useLicense from '../utils/use-license';
import ModalBase from './ModalBase';


type FormData = {
    name: string;
    numberOfSeats: number;
}

const FormSchema = yup.object<FormData>({
    name: yup.string().required("Namn måste vara angivet."),
    numberOfSeats: yup.number().min(0, "Antal säten kan som minst vara 0.").required("Antal säten måste vara angivet.")
});

interface FormProps {
    onFormSuccess: () => void;
}

const CreateSeatForm:React.FC<FormProps> = ({ onFormSuccess, children }) => {

    const { createSeatLicense } = useLicense();
    const toast = useToast();

    const [hasFormError, setHasFormError] = React.useState(false);
    const { handleSubmit, errors, register, formState } = useForm<FormData>({ resolver: yupResolver(FormSchema) });

    const nameRef = React.useRef<HTMLInputElement | null>(null);

    const handleOnSubmit = handleSubmit(async ({name, numberOfSeats}) => {
        
        setHasFormError(false);

        const success = await createSeatLicense(name, numberOfSeats);

        if (success !== null) {
            toast({
                position: "bottom-right",
                title: "Säteslicensen tillagd.",
                description: "Säteslicensen blev tillagd med lyckat resultat.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            onFormSuccess();
        } else {
            setHasFormError(true);
        }
    });

    React.useEffect(() => {
        nameRef.current && nameRef.current.focus();
    }, []);

    return (
        <Box as="form" onSubmit={handleOnSubmit}>
            <FormControl isInvalid={errors.name !== undefined} mb={4}>
                <FormLabel htmlFor="name">Licensnamn</FormLabel>
                <Input
                    id="name"
                    name="name"
                    variant="filled"
                    placeholder="ex. Advania kommun"
                    ref={(e: HTMLInputElement) => {
                        register(e);
                        nameRef.current = e;
                    }}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="numberOfSeats">Antal säten</FormLabel>
                <Input
                    type="number"
                    placeholder="0 eller mer"
                    name="numberOfSeats"
                    variant="filled"
                    ref={register}
                    aria-describedby="seats-helper-text"
                />
                <FormHelperText id="seats-helper-text">
                    Anges 0 säten så är det lika med obegränsat antal.    
                </FormHelperText>
                <FormErrorMessage>
                    {errors.numberOfSeats && errors.numberOfSeats.message}
                </FormErrorMessage>
            </FormControl>
            <Flex
                align="center"
                justify="flex-end"
                pt={8}
                pb={2}
            >   
                <Button
                    variantColor="brand"
                    isLoading={formState.isSubmitting}
                    type="submit"
                    order={2}
                >
                    Lägg till
                </Button>
                {children}
            </Flex>
            {hasFormError && (
                <Alert status="error" borderRadius="2px">
                    <AlertIcon />
                    <Flex direction="column">
                    <AlertTitle>Något gick fel</AlertTitle>
                    <AlertDescription>Något gick fel när säteslicensen skulle skapas. Vänligen försök igen!</AlertDescription>
                    </Flex>
              </Alert>
            )}
        </Box>
    );
}

type CreateSeatModalType = {
    isOpen: boolean;
    onClose: () => void;
};

const CreateSeatModal: React.FunctionComponent<CreateSeatModalType> = ({ isOpen, onClose }) => {

    const handleOnSuccess = () => {
        onClose();
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} modalTitle="Lägg till säteslicens">
            <CreateSeatForm onFormSuccess={handleOnSuccess}>
                <Button mr={4} order={0} variant="ghost" onClick={onClose}>
                    Avbryt
                </Button>
            </CreateSeatForm>
        </ModalBase>
    );
}

export default CreateSeatModal;