import React from 'react';
import { Flex, Button, Image, Box, ButtonProps } from '@chakra-ui/core';
import { Link as RouterLink, useRouteMatch, LinkProps as RouterLinkProps } from 'react-router-dom';
import useAuth from '../utils/use-auth';
import { useAppContext } from '../utils/AppContext'; 

import Logotype from '../images/logo.webp';

interface MenuItemProps {
    to: string;
    activeOnlyWhenExact?: boolean;
}

type LinkRouterLinkProps = ButtonProps & RouterLinkProps;

export const MenuItemLink: React.FC<LinkRouterLinkProps> = React.forwardRef((props: LinkRouterLinkProps, ref: React.Ref<any>) => {
    return <Button ref={ref} as={RouterLink} {...props} />
});

const MenuItems: React.FunctionComponent<MenuItemProps> = ({ children, to, activeOnlyWhenExact = true }) => {

    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
      });

    return (
        <MenuItemLink to={to} mt={{ base: 4, md: 0 }} display="flex" alignItems="center" fontWeight={match ? "bold" : "normal"} bg="transparent">
        {children}
        </MenuItemLink>
    );
}

const Header = () => {

    const context = useAppContext();
    const { logout } = useAuth();
    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);

    return (
        <>
        {context.user.get !== null && (
        <Box
            as="header"
            bg="white"
            shadow="0 4px 12px -1px rgba(0, 0, 0, 0.06), 0 2px 10px -1px rgba(0, 0, 0, 0.02)"
            mb={8}
        >
        <Flex
            align="center"
            justify="space-between"
            wrap="wrap"
            padding={5}
            maxWidth={{base: "full", md: "48em", lg: "62em" ,xl: "80em"}}
            mx={{base: 0, md: 'auto'}}
        >
            <Flex align="center" mr={8}>
                <MenuItemLink to="/" fontWeight="bold" fontSize="lg" variant="link" variantColor="black">
                    <Image src={Logotype} w="3xs" />
                </MenuItemLink>
            </Flex>
  
            <Button display={{ base: "block", md: "none" }} onClick={handleToggle}>
            <svg
                fill="gray.600"
                width="12px"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>Meny</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            </Button>
  
            <Box
            as="nav"
            display={{ base: show ? "block" : "none", md: "flex" }}
            width={{ base: "full", md: "auto" }}
            alignItems="center"
            flexGrow={1}
            >
                <MenuItems to="/licenser" activeOnlyWhenExact={false}>Licenser</MenuItems>
                {context.user.get.isAdmin && <MenuItems to="/anvandare" activeOnlyWhenExact={false}>Användare</MenuItems>}
            </Box>
  
            <Box
            display={{ base: show ? "flex" : "none", md: "flex" }}
            mt={{ base: 4, md: 0 }}
            alignItems="center"
            justifyContent={{base: 'space-between', md: 'flex-start'}}
            width={{base: 'full', md: 'auto'}}
            >
                <Box as="span" display="block" mr={4}>
                    Inloggad som <strong>{context.user.get.username}</strong>
                </Box>
                <Button variant="solid" variantColor="gray" onClick={logout}>
                    Logga ut
                </Button>
            </Box>
        </Flex>
        </Box>
        )}
        </>
    );
}

export default Header;