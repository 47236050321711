import { useAppContext } from './AppContext';
import useAuth from './use-auth';

export type CreateUserRequestType = {
    userName: string;
    email: string;
    password: string;
    isAdmin: boolean;
}

export type UserType = {
    userId: number;
    userName: string;
    email: string;
    role: string;
}

const useUser = () => {

    const context = useAppContext();
    const usersUrl = `${context.baseUrl.get}/api/users`;

    const { authPostRequest, authGetRequest, authPutRequest, authDeleteRequest } = useAuth();

    const createUser = async (user: CreateUserRequestType) => {
        if (!context.user.get?.isAdmin) {
            return null;
        }

        return await authPostRequest<UserType>(usersUrl, {...user});
    }

    const getAllUsers = async () => {
        if (!context.user.get?.isAdmin) {
            return null;
        }

        return await authGetRequest<UserType[]>(usersUrl);
    }

    const getSingleUser = async (userId: string) => {
        if (!context.user.get?.isAdmin) {
            return null;
        }

        return await authGetRequest<UserType>(`${usersUrl}/${userId}`);
    }

    const deleteSingleUser = async (userId:number) => {
        if (!context.user.get?.isAdmin) {
            return null;
        }

        return await authDeleteRequest<boolean>(`${usersUrl}/${userId}`);
    }

    return {
        createUser,
        getAllUsers,
        getSingleUser,
        deleteSingleUser
    }
}

export default useUser;