import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';

import customTheme from './theme';
import AppContextProvider from './utils/AppContext';
import App from './components/App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <AppContextProvider initialBaseUrl={/*`https://localhost:44333` `https://lic-advaniavoice-test.azurewebsites.net`*/`https://licens.advaniavoice.se`}>
      <BrowserRouter basename={baseUrl ? baseUrl : undefined}>
        <ThemeProvider theme={customTheme}>
          <CSSReset />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </AppContextProvider>,
  rootElement);

