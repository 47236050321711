import React from 'react';
import { Box, FormControl, FormLabel, Input, FormErrorMessage, FormHelperText, Flex, Button, Alert, AlertIcon, AlertTitle, AlertDescription, useToast, Heading, Text, Checkbox } from '@chakra-ui/core';
import { useForm, NestedValue, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import useLicense, { SeatLicenseModel } from '../utils/use-license';
import ModalBase from './ModalBase';

type FormDataProps = {
    files: NestedValue<File[]>;
    clear: boolean;
}

interface FormProps {
    onFormSuccess: (addedSeats: SeatLicenseModel[], clearStatus: boolean) => void;
    activeLicenseId: number;
}

const AddSeatsForm:React.FC<FormProps> = ({ onFormSuccess, children, activeLicenseId }) => {

    const { addSeatsFileToLicense } = useLicense();
    const toast = useToast();

    const [hasFormError, setHasFormError] = React.useState(false);
    const { handleSubmit, errors, register, formState } = useForm<FormDataProps>();

    const nameRef = React.useRef<HTMLInputElement | null>(null);

    const handleOnSubmit: SubmitHandler<FormDataProps> = (async ({files, clear}) => {
        

        let formData = new FormData();
        formData.append('file', files[0], files[0].name);
        formData.append('clear', JSON.stringify(clear));

        setHasFormError(false);

        const success = await addSeatsFileToLicense(activeLicenseId, formData);

        if (success !== null) {
            toast({
                position: "bottom-right",
                title: "Säten tilldelade.",
                description: "Sätena blev tilldelat med lyckat resultat.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            onFormSuccess(success, clear);
        } else {
            setHasFormError(true);
        }
    });

    React.useEffect(() => {
        nameRef.current && nameRef.current.focus();
    }, []);

    return (
        <Box as="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Box mb={4} p={4} bg="gray.100" borderRadius={4}>
                <Heading as="h3" size="md" mb={2}>
                    Information
                </Heading>
                <Text mb={2}>Om antalet säten i licensen är begränsade så kommer endast den lediga mängden säten läggas till från filen (uppifrån och ned).</Text>
                <Text>
                    Tilldelning av säten via fil kan ta lite tid. 
                </Text>
            </Box>
            <FormControl isInvalid={errors.files !== undefined} mb={4}>
                <FormLabel htmlFor="files">Fil från GSuite (.csv)</FormLabel>
                <Input
                    type="file"
                    id="files"
                    name="files"
                    variant="filled"
                    placeholder="CSV-fil från GSuite"
                    accept=".csv"
                    ref={(e: HTMLInputElement) => {
                        register(e);
                        nameRef.current = e;
                    }}
                />
                <FormErrorMessage>
                    {errors.files && errors.files.message}
                </FormErrorMessage>
            </FormControl>
            <FormControl mb={4}>
                <Checkbox
                    id="clear"
                    name="clear"
                    ref={register}
                >
                    Rensa nuvarande tilldelade säten
                </Checkbox>
                
                <FormHelperText color="red.500">
                    Om markerad så tas alla nuvarande tilldelade säten bort innan filens innehåll läggs till.
                </FormHelperText>
                <FormErrorMessage>
                    {/* {errors.isAdmin && errors.isAdmin.message} */}
                </FormErrorMessage>
            </FormControl>
            <Flex
                align="center"
                justify="flex-end"
                pt={8}
                pb={2}
            >   
                <Button
                    variantColor="brand"
                    isLoading={formState.isSubmitting}
                    type="submit"
                    order={2}
                >
                    Läs in fil
                </Button>
                {children}
            </Flex>
            {hasFormError && (
                <Alert status="error" borderRadius="2px">
                    <AlertIcon />
                    <Flex direction="column">
                    <AlertTitle>Något gick fel</AlertTitle>
                    <AlertDescription>Något gick fel när filen skulle laddas upp och säten skulle tilldelas. Vänligen försök igen!</AlertDescription>
                    </Flex>
              </Alert>
            )}
        </Box>
    );
}

type AddSeatsFileModalType = {
    isOpen: boolean;
    onClose: () => void;
    activeLicenseId: number;
    onSuccess: (addedSeats: SeatLicenseModel[], clearStatus: boolean) => void;
};

const AddSeatsFileModal: React.FunctionComponent<AddSeatsFileModalType> = ({ isOpen, onClose, activeLicenseId, onSuccess }) => {

    const handleOnSuccess = (addedSeats: SeatLicenseModel[], clearStatus: boolean) => {
        onClose();
        onSuccess(addedSeats, clearStatus);
    }

    return (
        <ModalBase isOpen={isOpen} onClose={onClose} modalTitle="Tilldela säte">
            <AddSeatsForm onFormSuccess={handleOnSuccess} activeLicenseId={activeLicenseId}>
                <Button mr={4} order={0} variant="ghost" onClick={onClose}>
                    Avbryt
                </Button>
            </AddSeatsForm>
        </ModalBase>
    );
}

export default AddSeatsFileModal;