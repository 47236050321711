import React from 'react';
import {Box, Flex, useTheme} from '@chakra-ui/core';
import Header from './Header';

const Layout: React.FunctionComponent = ({children}) => {

    return (
        <Flex direction="column" minH="100vh" bg="gray.100">
            <Header />
            <Box
                w="full"
                maxWidth={{base: "full", md: "48em", lg: "62em" ,xl: "80em"}}
                mx={{base: 0, md: 'auto'}}
                px={5}
                flex="auto"
            >
            {children}
            </Box>
        </Flex>
    );
}

export default Layout;