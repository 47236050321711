import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useAppContext } from './AppContext';

export const AuthRoutes = {
    Login: '/login'
}

export const UserStorageKey = 'tokens';

export type TokenData = {
    sub: string;
    rol: string;
}

type JWTData = {
    accessToken: { token: string, expiresIn: number };
    refreshToken: string;
}

const useAuth = () => {

    const context = useAppContext();
    const authUrl = `${context.baseUrl.get}/api/auth`;

    const login = (email: string, password: string): Promise<boolean> => {
        return axios
            .post(`${authUrl}/challenge`, {email, password})
            .then(response => {
                
                if (response.data?.accessToken?.token) {
                    // Decode JWT to get the users values and save to state
                    const userData: TokenData = jwt_decode(response.data.accessToken.token);
    
                    context.user.set({
                        authorized: true,
                        username: userData.sub,
                        isAdmin: userData.rol === "superadmin"
                    });
    
                    // Save the tokens to localstorage
                    localStorage.setItem(UserStorageKey, JSON.stringify(response.data));

                    return true;
                } else {
                    return false;
                }

            }).catch(e => {
                
                console.log(`Something went wrong when performing login request: ${e}`);
                return false;

            });
    }

    const logout = (): void => { 
        localStorage.removeItem(UserStorageKey); 
        context.user.set(null);
    }

    const getAccessToken = (): string | null => {
        
        const localStorageData = localStorage.getItem(UserStorageKey);
        if (!localStorageData)
            return null;

        const data = JSON.parse(localStorageData);
        if (!data)
            return null;

        return data.accessToken.token;
    };

    const getRefreshToken = (): string | null => {
        const localStorageData = localStorage.getItem(UserStorageKey);
        if (!localStorageData)
            return null;

        const data = JSON.parse(localStorageData);
        if (!data)
            return null;

        return data.refreshToken;
    }

    const getCurrentUser = (): Promise<void> => {
        
        const tokenData = localStorage.getItem(UserStorageKey);
        
        if (tokenData) {
            const parsedTokenData: JWTData = JSON.parse(tokenData);

            return axios.post(`${authUrl}/refresh`, { accessToken: parsedTokenData.accessToken.token, refreshToken: parsedTokenData.refreshToken })
            .then(response  => {

                if (response.data?.accessToken?.token) {
                    // Decode JWT to get the users values and save to state
                    const userData: TokenData = jwt_decode(response.data.accessToken.token);
    
                    context.user.set({
                        authorized: true,
                        username: userData.sub,
                        isAdmin: userData.rol === "superadmin"
                    });
    
                    // Save the tokens to localstorage
                    localStorage.setItem(UserStorageKey, JSON.stringify(response.data));
                    
                } else {
                    context.user.set(null);                    
                }
            })
            .catch(e => {
                console.log("Something went wrong while refreshing user data", e);
                
            });
        } else {
            return Promise.reject();
        }
    }

    function authUploadRequest<T> (url: string, formData: FormData): Promise<T | null> {
        return axios.post(url, formData, { 
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e);
            return null;
        });
    }

    function authPostRequest<T> (url: string, postData: { [key: string]: string | string[] | number | number[] | boolean | object }): Promise<T | null> {
        return axios.post(url, postData, { 
            headers: {
                'Authorization': `Bearer ${getAccessToken()}` 
            }
        }).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e);
            return null;
        });
    } 

    function authPutRequest<T> (url: string, postData: { [key: string]: string | string[] | number }): Promise<T | null> {
        return axios.put(url, postData, { 
            headers: {
                'Authorization': `Bearer ${getAccessToken()}` 
            }
        }).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e);
            return null;
        });
    } 

    function authDeleteRequest<T> (url: string) : Promise<T | null> {
        return axios.delete(url, { 
            headers: {
                'Authorization': `Bearer ${getAccessToken()}` 
            }
        }).then(response => {
            return response.data;
        }).catch(e => {
            console.log(e);
            return null;
        });
    }

    function authGetRequest<T> (url: string): Promise<T | null> {
        return axios.get(url, { 
            headers: {
                'Authorization': `Bearer ${getAccessToken()}` 
            }
        }).then(response => {
            // console.log(response.data);
            return response.data;
        }).catch(e => {
            console.log(e);
            return null;
        });
    }

    return {
        login,
        logout,
        getAccessToken,
        getRefreshToken,
        getCurrentUser,
        authPostRequest,
        authGetRequest,
        authPutRequest,
        authDeleteRequest,
        authUploadRequest
    }
}

export default useAuth;