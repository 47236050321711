import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Flex, 
    Heading, 
    Spinner, 
    Tag, 
    Button, 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader, 
    AlertDialogBody, 
    AlertDialogFooter, 
    Stack, 
    Stat, 
    StatLabel, 
    StatNumber,
    IconButton,
    useToast,
    AvatarGroup,
    Avatar,
    Tooltip,
    Text
} from '@chakra-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiTrash2, FiPlus, FiUploadCloud, FiUsers } from 'react-icons/fi'

import { useAppContext } from '../utils/AppContext';
import useLicense, { SingleLicenseFullModel, SeatLicenseModel, LicenseAdministrator } from '../utils/use-license';
import useSeat from '../utils/use-seat';
import { MenuItemLink } from '../components/Header';
import AddSingleSeatModal from '../components/AddSingleSeatModal';
import AddSeatsFileModal from '../components/AddSeatsFileModal';
import ManageAdministratorsModal from '../components/ManageAdministratorsModal';

const SeatListView = ({seats, onDeleteClick}: { seats: SeatLicenseModel[] | null, onDeleteClick: (id: number) => void }) => {

    return (
        <>
        {seats !== null && seats.length > 0 
        ? seats.map(item => 
            (<Flex justify="space-between" align="center" p={4} mb={4} bg="gray.100" borderRadius={4} key={item.seatLicenseId}>
                <Box as="span" fontWeight="medium" fontSize="lg">{item.email}</Box>
                <IconButton variantColor="red" aria-label="Ta bort" icon={FiTrash2} variant="ghost" onClick={() => onDeleteClick(item.seatLicenseId)} />
            </Flex>))
        : (
            <Box bg="gray.100" borderRadius={4} p={4} textAlign="center">
                <Box as="span" fontWeight="medium" fontSize="lg">
                    Det är inga säten tilldelade i denna licens ännu.
                </Box>
            </Box>
        )
        }
        </>
    );
}

const LicenseView = (props: SingleLicenseFullModel) => {

    const context = useAppContext();
    const history = useHistory();
    const toast = useToast();

    const { deleteSeat } = useSeat();
    const { deleteSingleLicense } = useLicense();

    const { licenseId, licenseType, name, numberOfSeats, domainLicenses, seatLicenses, administrators } = props;

    // const [loadedDomainLicenses, setLoadedDomainLicenses] = useState(domainLicenses);
    const [loadedSeatLicenses, setLoadedSeatLicenses] = useState(seatLicenses);
    const [activeAdministrators, setActiveAdministrators] = useState<LicenseAdministrator[] | null>(administrators);

    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const handleOnDeleteClose = () => setIsDeleteOpen(false);
    const cancelRef = React.createRef<HTMLElement>();

    const [manageAdminIsOpen, setManageAdminIsOpen] = useState(false);
    const handleOnManageAdminClose = () => setManageAdminIsOpen(false);
    const handleOnManageAdminSuccess = (newAdministrators: LicenseAdministrator[]) => {
        
        setActiveAdministrators(newAdministrators);
    }

    // Single Seat add-modal
    const [addSeatIsOpen, setAddSeatIsOpen] = useState(false);
    const handleOnAddSeatClose = () => setAddSeatIsOpen(false);
    const handleOnAddSeatSuccess = (addedSeat: SeatLicenseModel) => {
        if (loadedSeatLicenses !== null) {
            setLoadedSeatLicenses([...loadedSeatLicenses, addedSeat]);
        }
    }

    // Seat-file add-modal
    const [addSeatsFileIsOpen, setAddSeatsFileIsOpen] = useState(false);
    const handleOnAddSeatsFileClose = () => setAddSeatsFileIsOpen(false);
    const handleOnAddSeatsFileSuccess = (addedSeats: SeatLicenseModel[], clearStatus: boolean) => {
        if (loadedSeatLicenses !== null) {
            clearStatus ? setLoadedSeatLicenses(addedSeats) : setLoadedSeatLicenses([...loadedSeatLicenses, ...addedSeats]);
        }
    }

    const handleDeleteLicenseClick = async () => {
        const deleteStatus = await deleteSingleLicense(licenseId);

        if (deleteStatus) {
            toast({
                position: "bottom-right",
                title: "Licensen borttagen.",
                description: "Licensen blev borttagen med lyckat resultat.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            history.push('/licenser');
        }
    }

    const handleDeleteSeatClick = async (id: number) => {
        const deleteStatus = await deleteSeat(id);

        if (deleteStatus && loadedSeatLicenses !== null) {
            const seatIndex = loadedSeatLicenses.findIndex(seat => seat.seatLicenseId === id);
            if (seatIndex >= 0) {
                loadedSeatLicenses.splice(seatIndex, 1);
                setLoadedSeatLicenses([...loadedSeatLicenses]);

                toast({
                    position: "bottom-right",
                    title: "Sätestilldelning borttagen.",
                    description: "Sätestilldelningen blev borttagen med lyckat resultat.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    }

    return (
        <>
        <Flex mx={-5} mt={-5} p={5} borderBottom="1px solid" borderBottomColor="gray.200" mb={8} align="center" justify="space-between">
            <MenuItemLink to="/licenser" leftIcon={FiArrowLeft}>
                Tillbaka
            </MenuItemLink>

            {context.user.get?.isAdmin && (
                <Box>
                    {licenseType === 'seat' && (
                        <Button mr={5} variant="solid" leftIcon={FiUsers} onClick={() => setManageAdminIsOpen(true)}>
                            Administratörer
                        </Button>
                    )}
                    <Button variantColor="red" variant="outline" leftIcon={FiTrash2} onClick={() => setIsDeleteOpen(true)}>
                        Ta bort
                    </Button>
                </Box>
            )}
        </Flex>
        <Flex align="center" justify="space-between" mb={8} width="full">
            <Box width="full">
                <Flex align="center" mb={4} justify="space-between" width="full">
                    <Tag variantColor={licenseType === 'domain' ? 'brand' : 'blue'} mr={4}>{licenseType === 'domain' ? 'Domänlicens' : 'Säteslicens'}</Tag>
                    {activeAdministrators && activeAdministrators.length > 0 && (
                        <Flex align="center" >
                            <Text fontSize="xs">Administratörer</Text>
                            <AvatarGroup ml={2} size="sm" max={2} >
                                {activeAdministrators.map(item => <Avatar name={item.userName} key={item.id + item.userName} />)}
                            </AvatarGroup>
                        </Flex>
                    )}
                </Flex>
                <Heading as="h1">
                    {name}
                </Heading>
            </Box>
        </Flex>
        <Box>
            {licenseType === 'seat' && (
                <Stack isInline align="center" spacing={4} mb={8}>
                    <Stat bg="gray.100" p={4} borderRadius={4}>
                        <StatLabel>Totalt antal säten</StatLabel>
                        <StatNumber>{numberOfSeats === 0 ? 'Obegränsat' : numberOfSeats}</StatNumber>
                    </Stat>
                    {loadedSeatLicenses && (
                    <Stat bg="gray.100" p={4} borderRadius={4}>
                        <StatLabel>Antal säten kvar</StatLabel>
                        <StatNumber>{numberOfSeats === 0 ? 'Obegränsat' : numberOfSeats - loadedSeatLicenses.length}</StatNumber>
                    </Stat>
                    )}
                </Stack>
            )}
            <Flex justify="space-between" align="center" mb={4}>
                <Heading as="h2" size="lg">
                    {licenseType === 'domain' ? 'Domäner' : 'Säten'}
                </Heading>
                {licenseType === 'seat' && (
                <Box>
                    <Button leftIcon={FiUploadCloud} size="sm" mr={4} onClick={() => setAddSeatsFileIsOpen(true)}>
                        Läs in fil
                    </Button>
                    <Button variantColor="blue" leftIcon={FiPlus} size="sm" onClick={() => setAddSeatIsOpen(true)}>
                        Tilldela säte
                    </Button>
                </Box>
                )}
            </Flex>
            <Stack spacing={4}>
                {licenseType === 'domain' 
                ? domainLicenses && domainLicenses.map(item => 
                    (<Box p={4} bg="gray.100" borderRadius={4} key={item.domainLicenseId}>
                        <Box as="span" fontWeight="medium" fontSize="lg">@{item.domain}</Box>
                    </Box>))
                : <SeatListView seats={loadedSeatLicenses} onDeleteClick={handleDeleteSeatClick} />
                }
            </Stack>
        </Box>
        <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={handleOnDeleteClose}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Ta bort licens
            </AlertDialogHeader>

            <AlertDialogBody>
                Är du säker på att du vill ta bort licensen <strong>{name}</strong>?
            </AlertDialogBody>

            <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleOnDeleteClose}>
                Nej, avbryt
                </Button>
                <Button variantColor="red" onClick={handleDeleteLicenseClick} ml={4}>
                Ja, ta bort
                </Button>
            </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
        {licenseType === 'seat' && <AddSingleSeatModal activeLicenseId={licenseId} isOpen={addSeatIsOpen} onClose={handleOnAddSeatClose} onSuccess={handleOnAddSeatSuccess} />}
        {licenseType === 'seat' && <AddSeatsFileModal activeLicenseId={licenseId} isOpen={addSeatsFileIsOpen} onClose={handleOnAddSeatsFileClose} onSuccess={handleOnAddSeatsFileSuccess} />}
        {licenseType === 'seat' && <ManageAdministratorsModal activeLicenseId={licenseId} existingAdministrators={activeAdministrators} isOpen={manageAdminIsOpen} onClose={handleOnManageAdminClose} onSuccess={handleOnManageAdminSuccess}/>}
        </>
    );
}

const SingleLicense = () => {

    const { id } = useParams();
    const { getSingleLicense } = useLicense();

    const [loadedLicense, setLoadedLicense] = useState<SingleLicenseFullModel | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const loadLicense = async (id: number) => {
        
        const license = await getSingleLicense(id);
        console.log(license);
        setLoadedLicense(license);
        setIsLoading(false);
    }

    useEffect(() => {
        loadLicense(id);
    }, [id]);

    return (
        <Box
            h="full"
            minH="-webkit-fill-available"
            bg="white"
            rounded="md"
            p={5}
            mb={8}
        >
            {isLoading 
            ? <Spinner /> 
            : loadedLicense === null 
                ? <Box>Antingen saknar du behörighet, eller så finns inte den angivna licensen.</Box>
                : <LicenseView {...loadedLicense} />
            }
        </Box>
    );
}

export default SingleLicense;