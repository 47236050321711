import React, { useEffect, useState } from 'react';
import { Flex, Spinner } from '@chakra-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../utils/AppContext';
import LoginForm from '../components/LoginForm';
import useAuth from '../utils/use-auth';

const Login = () => {
    
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();
    const context = useAppContext();
    const { getCurrentUser } = useAuth();

    useEffect(() => {
        
        const getUserFromServer = async () => {
            setIsLoading(true);
            try {
                console.log("Checking to see if any tokens are available and eligble.");
                await getCurrentUser();
            } catch (e) {
                console.log("No eligble tokens available.");
            }
            setIsLoading(false);
        }
        
        // This will happen when the user hard-refresh the page as the context will repopulate
        if (context.user.get === null)
            getUserFromServer();
    }, []);

    useEffect(() => {
        if (!isLoading && context.user.get !== null) {
            performRedirect();
        }
    }, [context.user.get, isLoading]);

    const getReturnUrl = () => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get("returnUrl");
        
        return fromQuery || `/`;
    }

    const performRedirect = () => {
        const targetRoute = getReturnUrl();
        console.log(`Redirecting to ${targetRoute}`);
        history.push(targetRoute);
    }

    const handleFormSuccess = (): void => {
        // performRedirect();
    }

    return (
        <Flex align="center" justify="center" height="100vh" bg="white">
            {isLoading ? <Spinner /> : <LoginForm onFormSuccess={handleFormSuccess} />}
        </Flex>
    );
}

export default Login;