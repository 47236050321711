import React from 'react';
import { Route, RouteProps, Redirect, useRouteMatch } from 'react-router-dom';
import { AuthRoutes } from '../utils/use-auth';
import { useAppContext } from '../utils/AppContext';

type DynamicRouteParams = {
    id: string;
}

type ProtectedProps = {
    requireAdmin: boolean;
}

type ProtectedRouteProps = RouteProps & ProtectedProps;

const ProtectedRoute = (routeProps: ProtectedRouteProps) => {

    const context = useAppContext();
    const licenseMatch = useRouteMatch<DynamicRouteParams>("/licenser/:id");
    const userMatch = useRouteMatch<DynamicRouteParams>("/anvandare/:id");

    var link = document.createElement("a");
    link.href = Array.isArray(routeProps.path) ? routeProps.path[0] : (routeProps.path ?? '/');

    if (licenseMatch?.isExact) {
        link.pathname = link.pathname.replace(':id', licenseMatch.params.id);
    }
    else if (userMatch?.isExact) {
        link.pathname = link.pathname.replace(':id', userMatch.params.id);
    }

    const returnUrl = `${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${AuthRoutes.Login}?returnUrl=${encodeURI(returnUrl)}`;

    const { component: RouteComponent, ...rest } = routeProps;

    return <Route {...rest}
                    render={(props) => {
                    if (context.user.get !== null && routeProps.requireAdmin && !context.user.get.isAdmin) {
                        return <div>Ej behörig att se denna sida / Sidan finns inte</div>;
                    }
                    else if (context.user.get !== null && (routeProps.requireAdmin ? context.user.get.isAdmin : true) && RouteComponent) {
                        return <RouteComponent {...props} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
    
}

export default ProtectedRoute;