import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    IModal
  } from "@chakra-ui/core";

export interface ModalBaseProps extends IModal {
    modalTitle: string;
}

const ModalBase: React.FunctionComponent<ModalBaseProps> = ({children, isOpen, onClose, modalTitle}) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius={4}>
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalBase;
