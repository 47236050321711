import React, { useState, useEffect } from 'react';
import { Box, Heading, Flex, Button, Spinner, SimpleGrid, Tag } from '@chakra-ui/core';
import {FiPlus} from 'react-icons/fi';

import useUser, { UserType } from '../utils/use-user';
import { MenuItemLink } from '../components/Header';
import CreateUserModal from '../components/CreateUserModal';

const Users = () => {

    const { getAllUsers } = useUser();

    const [userOpen, setUserOpen] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [users, setUsers] = useState<UserType[] | null>(null);

    const loadUsers = async () => {
        const fetchedUsers = await getAllUsers();

        setIsLoadingUsers(false);
        setUsers(fetchedUsers);
    }

    const handleOnUserModalClose = () => {
        setIsLoadingUsers(true);
        setUserOpen(false);
        loadUsers();
    }

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <>
        <Box
        h="full"
        minH="-webkit-fill-available"
        bg="white"
        rounded="md"
        p={5}
        mb={8}
        >
            <Flex align="center" justify="space-between" mb={8}>
                <Heading as="h1">
                    Användare
                </Heading>
                <Button leftIcon={FiPlus} onClick={() => setUserOpen(true)}>
                    Skapa användare
                </Button>
            </Flex>
            {isLoadingUsers 
            ? <Flex justify="center" p={8}><Spinner /></Flex>
            : users !== null && users.length > 0 ? (
                <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} spacing={4}>
                {users.map(item => (
                <Box key={item.userId} bg="gray.100" p={4} borderRadius={4}>
                    <Tag variantColor={item.role && item.role.toLowerCase() === 'superadmin' ? 'brand' : 'blue'} mb={4}>{item.role}</Tag>
                    <Heading as="h3" size="lg" mb={8}>{item.userName}</Heading>
                    <Flex justify="flex-end">
                        <MenuItemLink to={`/anvandare/${item.userId}`}>
                            Visa
                        </MenuItemLink>
                    </Flex>
                </Box>
                ))}
            </SimpleGrid>
            ): (
                <Box bg="gray.100" borderRadius={4} p={4} textAlign="center">
                    <Box as="span" fontWeight="medium" fontSize="lg">
                        Det finns inga användare, kom igång via knappen "Skapa användare".
                    </Box>
                </Box>
            )
            }
        </Box>
        <CreateUserModal isOpen={userOpen} onClose={handleOnUserModalClose} />
        </>
    );
}

export default Users;