import { theme } from "@chakra-ui/core";

// Let's say you want to add custom colors
const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: 
    {
      50: '#ffe5ff',
      100: '#f7baf9',
      200: '#ef8cf2',
      300: '#e860ec',
      400: '#e034e5',
      500: '#c61bcc',
      600: '#9b139f',
      700: '#700c73',
      800: '#430546',
      900: '#1a001b',
    },
  }
};

export default customTheme;