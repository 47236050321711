import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Button, Menu, MenuButton, MenuList, MenuItem, MenuButtonProps, ButtonProps, SimpleGrid, Tag, Spinner } from '@chakra-ui/core';
import { FiPlus } from 'react-icons/fi';

import { MenuItemLink } from '../components/Header';
import CreateDomainModal from '../components/CreateDomainModal';
import CreateSeatModal from '../components/CreateSeatModal';
import { useAppContext } from '../utils/AppContext';
import useLicense, { LicenseListModel } from '../utils/use-license';

type MenuButtonButtonProps = MenuButtonProps & ButtonProps;

const SpecialMenuButton: React.FC<MenuButtonButtonProps> = React.forwardRef((props: MenuButtonButtonProps, ref: React.Ref<any>) => {
    return <MenuButton ref={ref} as={Button} {...props} />
});

const Licensing = () => {

    const context = useAppContext();
    const { getLicenses } = useLicense();

    const [isLoadingLicenses, setIsLoadingLicenses] = useState(true);
    const [domainOpen, setDomainOpen] = useState(false);
    const [seatOpen, setSeatOpen] = useState(false);
    const [licenses, setLicenses] = useState<LicenseListModel[] | null>(null)

    const loadLicenses = async () => {
        const fetchedLicenses = await getLicenses();

        setIsLoadingLicenses(false);
        setLicenses(fetchedLicenses);
    }

    const handleOnDomainModalClose = () => {
        setIsLoadingLicenses(true);
        setDomainOpen(false);
        loadLicenses();
    }

    const handleOnSeatModalClose = () => {
        setIsLoadingLicenses(true);
        setSeatOpen(false);
        loadLicenses();
    }

    useEffect(() => {
        loadLicenses();
    }, []);

    return (
        <>
        <Box
        h="full"
        minH="-webkit-fill-available"
        bg="white"
        rounded="md"
        p={5}
        mb={8}
        >
            <Flex align="center" justify="space-between" mb={8}>
                <Heading as="h1">
                    Licenser
                </Heading>
                {context.user.get?.isAdmin && (
                <Menu>
                    <SpecialMenuButton as={Button} leftIcon={FiPlus}>
                        Lägg till licens
                    </SpecialMenuButton>
                    <MenuList>
                        <MenuItem onClick={() => setDomainOpen(true)}>Domänlicens</MenuItem>
                        <MenuItem onClick={() => setSeatOpen(true)}>Säteslicens</MenuItem>
                    </MenuList>
                </Menu>
                )}
            </Flex>
            {isLoadingLicenses 
            ? <Flex justify="center" p={8}><Spinner /></Flex>
            : licenses !== null && licenses.length > 0 ? (
                <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} spacing={4}>
                {licenses.sort((a,b) => {
                    if (a.name < b.name)
                        return -1;

                    if (a.name > b.name)
                        return 1;

                    return 0;
                }).map(item => (
                <Box key={item.licenseId} bg="gray.100" p={4} borderRadius={4}>
                    <Tag variantColor={item.licenseType === 'domain' ? 'brand' : 'blue'} mb={4}>{item.licenseType === 'domain' ? 'Domänlicens' : 'Säteslicens'}</Tag>
                    <Heading as="h3" size="lg" mb={8}>{item.name}</Heading>
                    <Flex justify="flex-end">
                        <MenuItemLink to={`/licenser/${item.licenseId}`}>
                            Visa
                        </MenuItemLink>
                    </Flex>
                </Box>
                ))}
            </SimpleGrid>
            ): (
                <Box bg="gray.100" borderRadius={4} p={4} textAlign="center">
                    <Box as="span" fontWeight="medium" fontSize="lg">
                        {context.user.get?.isAdmin 
                            ? <Box as="span">Det finns inga licenser, kom igång via knappen "Lägg till licens".</Box>
                            : <Box as="span">Du inga licenser kopplade till dig.</Box>
                        }
                    </Box>
                </Box>
            )
            }
            
        </Box>
        <CreateDomainModal isOpen={domainOpen} onClose={handleOnDomainModalClose} />
        <CreateSeatModal isOpen={seatOpen} onClose={handleOnSeatModalClose} />
        </>
    );
}

export default Licensing;